<template>
    <b-input-group :prepend="prefix" size="sm">
        <b-input type="number" :id="id" number v-model="displayId" :state="inputState"/>
    </b-input-group>
</template>
<script>
import {Component, Vue, Prop} from 'vue-property-decorator';
import {sprintf} from 'sprintf-js';
@Component
export default class DisplayId extends Vue {

    @Prop({type: String}) value;
    @Prop({type: String}) id;
    @Prop({type: String}) prefix;
    @Prop({type: Boolean, default: false}) showState;

    get displayId() {
        const dId = this.value || null;
        return null === dId ? '' : dId.substring(dId.indexOf('-') + 1);
    }

    set displayId(value) {
        this.$emit('input', sprintf('%s-%s', this.prefix, value));
    }

    get inputState() {
        return this.showState ? 0 < (parseInt(this.displayId, 10) || 0) : null;
    }
}
</script>
<style scoped>

</style>
