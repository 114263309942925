<template>
    <div>
        <b-card-group deck>
            <b-card class="mb-2">
                <b-card-body>
                    <b-table hover striped small stacked="md" :fields="fields" :items="tableData">
                        <template v-slot:thead-top="data">
                            <tr>
                                <th colspan="6">
                                    <b-row>
                                        <b-col>
                                            <b-select size="sm"
                                                      v-model="selectedGeneration"
                                                      :options="generationOptions"
                                                      @change="refreshTableData" />
                                        </b-col>
                                        <b-col>
                                            <b-button class="float-right"
                                                      v-b-modal.add-ma-modal
                                                      size="sm"
                                                      variant="dark">Add New</b-button>
                                        </b-col>
                                    </b-row>
                                </th>
                            </tr>
                        </template>
                        <template v-slot:cell(displayId)="row">
                            <div
                                    class="display-id"
                                    v-if="!row.item.editMode">{{row.item.displayId}}</div>
                        </template>
                        <template v-slot:cell(desc)="row">
                            <div v-if="!row.item.editMode">
                                <strong>{{ row.item.title }}</strong><br/>
                                <div v-html="$options.filters.formatDescription(row.item.description)"></div>
                            </div>
                        </template>
                        <template v-slot:cell(recommendedTime)="row">
                            <div v-if="!row.item.editMode">
                                {{row.item.recommendedTime}}
                            </div>
                        </template>
                        <template v-slot:cell(suggestions)="row">
                            <b-link
                                    v-if="!!row.item.suggestions"
                                    @click="downloadSuggestions(row.item.activityId)">suggestions</b-link>
                        </template>
                        <template v-slot:cell(reference)="row">
                            <b-link
                                    v-if="!!row.item.reference"
                                    @click="downloadReference(row.item.activityId)">reference</b-link>
                        </template>
                        <template v-slot:cell(buttons)="row">
                            <b-link @click="startEditing(row)" v-if="!row.item.editMode">edit</b-link>
                        </template>
                        <template v-slot:row-details="row">
                            <b-card-group deck>
                                <b-card border-variant="primary"
                                        header-bg-variant="primary"
                                        header-text-variant="white"
                                        :header="'Edit '+row.item.displayId">
                                    <b-card-body>
                                        <b-row>
                                            <b-col cols="2">
                                                <b-form-group
                                                        label="Display ID:"
                                                        :label-for="'edit-ma-display-id-'+row.item.id"
                                                        invalid-feedback="Required - display ID cannot be longer than 10 characters including prefix"
                                                        :state="errorState(row.item, 'displayId')"
                                                        size="sm">
                                                    <display-id :id="'edit-ma-display-id-'+row.item.id"
                                                                :prefix="row.item.displayIdPrefix"
                                                                v-model="row.item.workspace.displayId"
                                                                :state="errorState(row.item, 'displayId')"
                                                                size="sm" />
                                                </b-form-group>

                                            </b-col>
                                            <b-col cols="5">
                                                <b-form-group
                                                        label="Title:"
                                                        :label-for="'edit-ma-title-'+row.item.id"
                                                        invalid-feedback="Required"
                                                        :state="errorState(row.item, 'title')"
                                                        size="sm">
                                                    <b-input
                                                            :id="'edit-ma-title-'+row.item.id"
                                                            size="sm"
                                                            v-model="row.item.workspace.title"
                                                            :state="errorState(row.item, 'title')" />
                                                </b-form-group>
                                                <b-form-group
                                                        label="Description:"
                                                        :label-for="'edit-ma-desc-'+row.item.id"
                                                        invalid-feedback="Required"
                                                        :state="errorState(row.item, 'description')"
                                                        size="sm">
                                                    <b-textarea
                                                            :id="'edit-ma-desc-'+row.item.id"
                                                            v-model="row.item.workspace.description"
                                                            :state="errorState(row.item, 'description')"
                                                            size="sm" />
                                                </b-form-group>
                                            </b-col>
                                            <b-col cols="5">
                                                <b-form-group
                                                        label="Recommended Time:"
                                                        :label-for="'edit-ma-recommended-time-'+row.item.id"
                                                        size="sm">
                                                    <b-input
                                                            :id="'edit-ma-recommended-time-'+row.item.id"
                                                            v-model="row.item.workspace.recommendedTime"
                                                            size="sm" />
                                                </b-form-group>
                                                <b-form-group
                                                        label="Suggestions:"
                                                        :label-for="'edit-ma-suggestions-'+row.item.id"
                                                        :state="!row.item.suggestions"
                                                        size="sm"
                                                        invalid-feedback="WARNING: Uploading a new suggestions document will replace the existing document">
                                                    <b-input-group>
                                                        <b-form-file
                                                                :id="'edit-ma-suggestions-'+row.item.id"
                                                                size="sm"
                                                                :accept="'.pdf'"
                                                                placeholder="Attach file"
                                                                drop-placeholder="Drop file here"
                                                                v-model="row.item.workspace.suggestionsDoc" />
                                                        <b-input-group-addon>
                                                            <b-button
                                                                    size="sm"
                                                                    @click="row.item.workspace.suggestionsDoc = undefined">
                                                                <font-awesome-icon icon="times" />
                                                            </b-button>
                                                        </b-input-group-addon>
                                                    </b-input-group>
                                                </b-form-group>
                                                <b-form-group
                                                        label="Reference:"
                                                        :label-for="'edit-ma-reference-'+row.item.id"
                                                        :state="!row.item.reference"
                                                        size="sm"
                                                        invalid-feedback="WARNING: Uploading a new reference document will replace the existing document">
                                                    <b-input-group>
                                                        <b-form-file
                                                                :id="'edit-ma-reference-'+row.item.id"
                                                                size="sm"
                                                                :accept="'.pdf'"
                                                                placeholder="Attach file"
                                                                drop-placeholder="Drop file here"
                                                                v-model="row.item.workspace.referenceDoc"/>
                                                        <b-input-group-addon>
                                                            <b-button
                                                                    size="sm"
                                                                    @click="row.item.workspace.referenceDoc = undefined">
                                                                <font-awesome-icon icon="times" />
                                                            </b-button>
                                                        </b-input-group-addon>
                                                    </b-input-group>
                                                </b-form-group>
                                                <div class="clearfix">
                                                    <b-button-group size="sm" class="float-right">
                                                        <b-button
                                                                variant="primary"
                                                                @click="cancelEdit(row)">Cancel</b-button>
                                                        <b-button
                                                                variant="success"
                                                                :disabled="allowSave(row.item)"
                                                                @click="saveEdit(row)">Save</b-button>
                                                    </b-button-group>
                                                </div>
                                            </b-col>
                                        </b-row>
                                    </b-card-body>
                                </b-card>
                            </b-card-group>
                        </template>
                    </b-table>
                </b-card-body>
            </b-card>
        </b-card-group>
        <b-modal id="add-ma-modal"
                 title="Add New Mentorship Activity"
                 header-bg-variant="dark"
                 header-text-variant="white"
                 scrollable>
            <b-card>
                <b-form-group
                        label="Display ID:"
                        label-for="new-ma-display-id"
                        size="sm"
                        :state="errorState(newMA, 'displayId')"
                        invalid-feedback="Required - display ID cannot be longer than 10 characters including prefix">
                    <display-id
                            v-model="newMA.workspace.displayId"
                            :state="errorState(newMA, 'displayId')"
                            prefix="M"
                            id="new-ma-display-id"/>
                </b-form-group>
                <b-form-group
                        label="Title:"
                        label-for="new-ma-title"
                        size="sm"
                        :state="errorState(newMA, 'title')"
                        invalid-feedback="Required">
                    <b-form-input
                            id="new-ma-title"
                            v-model="newMA.workspace.title"
                            size="sm"
                            :state="errorState(newMA, 'title')" />
                </b-form-group>
                <b-form-group
                        label="Description:"
                        label-for="new-ma-description"
                        size="sm"
                        :state="errorState(newMA, 'description')"
                        invalid-feedback="Required">
                    <b-form-textarea
                            id="new-ma-description"
                            v-model="newMA.workspace.description"
                            size="sm"
                            :state="errorState(newMA, 'description')" />
                </b-form-group>
                <b-form-group
                        label="Recommended Time:"
                        label-for="new-ma-recommended-time"
                        size="sm">
                    <b-form-input
                            id="new-ma-recommended-time"
                            v-model="newMA.workspace.recommendedTime"
                            size="sm" />
                </b-form-group>
                <b-form-group
                        label="Suggestions:"
                        label-for="new-ma-suggestions"
                        size="sm">
                    <b-form-file
                            id="new-ma-suggestions"
                            size="sm"
                            :accept="'.pdf'"
                            placeholder="Attach file"
                            drop-placeholder="Drop file here"
                            v-model="newMA.workspace.suggestionsDoc" />
                </b-form-group>
                <b-form-group
                        label="Reference:"
                        label-for="new-ma-reference"
                        size="sm">
                    <b-form-file
                            id="new-ma-reference"
                            size="sm"
                            :accept="'.pdf'"
                            placeholder="Attach file"
                            drop-placeholder="Drop file here"
                            v-model="newMA.workspace.referenceDoc" />
                </b-form-group>
            </b-card>
            <template v-slot:modal-footer="{ hide }">
                <b-button-group size="sm">
                    <b-button
                            variant="primary"
                            @click="hide">Cancel</b-button>
                    <b-button
                            variant="success"
                            :disabled="allowSave(newMA)"
                            @click="saveNew(newMA)">Save</b-button>
                </b-button-group>
            </template>
        </b-modal>
    </div>
</template>
<script>
import {Component, Vue} from 'vue-property-decorator';
import Breadcrumb from '@/views/menu/breadcrumb/breadcrumb';
import store from '@/store/store';
import _ from 'underscore';
import {MA, NULL_MA} from '@/model/activity';
import DisplayId from '@/views/private/activities/DisplayId.vue';
import activityDao from '@/dao/activity_dao';
import {sprintf} from 'sprintf-js';
import {errorModalOptions, errorToastOptions} from "../../../util/formatters";

@Component({
    filters: {
        formatDescription: (desc) => {
            return (desc || '').replace(/\n/g, '<br/>');
        }
    },
    components: {
        DisplayId
    },
    asyncComputed: {
        tableData: {
            async get() {
                try {
                    await store.dispatch('activities/loadMAs');
                    const mas = this.$store.getters['activities/getMAs'];
                    const filteresMas = _.chain(mas)
                        .filter(ma => {
                            return _.isEqual(ma.generation, this.selectedGeneration);
                        })
                        .sortBy(ma => {
                            const maDisplayId = ma.displayId.substring(ma.displayId.indexOf('-') + 1);
                            return Number(maDisplayId);
                        })
                        .value();
                    return filteresMas;
                }
                catch (error) {
                    this.$bvToast.toast(error.message, errorToastOptions);
                }
            }
        }
    },
    methods: {
        refreshTableData() {
            this.$asyncComputed.tableData.update();
        }
    }
})
export default class MAPage extends Vue {
    selectedGeneration = this.currentGeneration;
    newMA = NULL_MA.clone();

    resetNewMA() {
        this.newMA = NULL_MA.clone();
        this.newMA.workspace.generation = this.currentGeneration;
    }

    errorState(ma, field) {
        return ma.workspace.maErrors[field];
    }

    allowSave(ma) {
        return _.any(ma.requiredFields, fieldName => {
            return !this.errorState(ma, fieldName);
        });
    }

    get currentGeneration() {
        const generation = store.getters['activities/currentActivityGeneration'];
        return generation;
    }

    get generationOptions() {
        const options = this.$store.getters['activities/activityGenerations'];
        return options;
    }

    get getMAs() {
        return this.$store.getters['activities/getMAs'];
    }

    get fields() {
        return [{
            key: 'displayId',
            label: 'ID',
            sortable: true
        }, {
            key: 'desc',
            label: 'Description',
            sortable: false
        }, {
            key: 'recommendedTime',
            label: 'Recommended Time',
            sortable: true
        }, {
            key: 'suggestions',
            label: 'Suggestions',
            sortable: true
        }, {
            key: 'reference',
            label: 'Reference',
            sortable: true
        }, {
            key: 'buttons',
            label: '',
            sortable: false
        }];
    }

    async beforeRouteEnter(to, from, next) {
        store.commit('addBreadcrumb', Breadcrumb.create('Mentorship Activities', null, true));
        next();
    }

    startEditing(row) {
        row.item.editMode = true;
        row.toggleDetails();
    }

    //Save changes to an existing MA
    async saveEdit(row) {
        //Get MA from row data
        const ma = row.item;
        //Correct display ID as necessary
        const displayId = sprintf('%s-%s',
            ma.displayIdPrefix,
            ma.workspace.displayId.replace(/^.*-(\d+)$/, '$1'));
        ma.workspace.displayId = displayId;
        try {
            //Save MA model changes as needed
            if (ma.hasChanges) {
                await activityDao.saveMA(ma.workspace);
            }
            //Upload docs as needed
            await this.uploadDocuments(ma.workspace);
            //Save changes
            ma.commit();
            //Restore row edit mode
            if (_.isFunction(row.toggleDetails)) {
                row.toggleDetails();
            }
            this.refreshTableData();
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            ma.rollback();
        }
    }

    //Save changes to new MA
    async saveNew(ma) {
        //Correct display ID as necessary
        const displayId = sprintf('%s-%s',
            ma.displayIdPrefix,
            ma.workspace.displayId.replace(/^.*-(\d+)$/, '$1'));
        ma.workspace.displayId = displayId;
        try {
            //Save MA
            const savedMa = await activityDao.saveMA(ma.workspace);
            //Get MA IDs and upload docs as needed
            ma.workspace.id = savedMa.id;
            ma.workspace.activityId = savedMa.activityId;
            await this.uploadDocuments(ma.workspace);
            //Save changes
            ma.commit();
            this.$store.commit('activities/addMA', ma);
            //Hide new MA modal
            this.$bvModal.hide('add-ma-modal');
            this.resetNewMA();
            this.refreshTableData();
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
            this.resetNewMA();
        }
    }

    //Check for and upload documents
    async uploadDocuments(ma) {
        try {
            if (!_.isUndefined(ma.suggestionsDoc) && !_.isNull(ma.suggestionsDoc)) {
                const success = await activityDao.uploadDocument('suggestions', ma.id, ma.suggestionsDoc);
                if (success) {
                    //Clear placeholders
                    ma.suggestions = true;
                    delete ma.suggestionsDoc;
                }
            }
            if (!_.isUndefined(ma.referenceDoc) && !_.isNull(ma.referenceDoc)) {
                const success = await activityDao.uploadDocument('reference', ma.id, ma.referenceDoc);
                if (success) {
                    //Clear placeholders
                    ma.reference = true;
                    delete ma.referenceDoc;
                }
            }
        }
        catch (error) {
            await this.$bvModal.msgBoxOk(error.message, errorModalOptions);
        }
    }

    cancelEdit(row) {
        row.item.rollback();
        row.toggleDetails();
    }

    mounted() {
        this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
            if (_.isEqual('add-ma-modal', modalId)) {
                this.resetNewMA();
            }
        });
    }

    async downloadSuggestions(activityId) {
        try {
            await activityDao.getDocument('suggestions', activityId);
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }

    async downloadReference(activityId) {
        try {
            await activityDao.getDocument('reference', activityId);
        }
        catch (error) {
            this.$bvToast.toast(error.message, errorToastOptions);
        }
    }
}
</script>
<style scoped>
    div.display-id {
        white-space: nowrap;
    }
</style>
